<template>
  <v-form v-model="valid" :readonly="pending" @submit.prevent="() => onSubmit()">
    <v-card-text>
      <v-text-field v-model="fileRemark" :label="$t('remark')" clearable autofocus />
    </v-card-text>

    <v-card-actions class="justify-end">
      <v-btn color="primary" @click="onCancel">{{ $t("cancel") }}</v-btn>
      <v-btn color="primary" type="submit" :disabled="valid === false" :loading="pending">{{ $t("save") }}</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script setup lang="ts">
const $i18n = useI18n()

const fileStore = useFileStore()
const mainStore = useMainStore()

const emit = defineEmits<{
  (event: "ok", value: string): void
  (event: "cancel"): void
}>()

const props = defineProps<{ fileData: FileData }>()
const { fileData } = toRefs(props)

const fileRemark = ref(fileData.value.remark)
const pending = ref(false)
const valid = ref<boolean | null>(null)

const onSubmit = () => {
  useExplicitSave(mainStore.notify, $i18n, async () => {
    if (!valid.value) {
      return
    }

    pending.value = true

    try {
      const newFileData = await fileStore.updateFile(fileData.value._id, { remark: fileRemark.value ?? "" })
      emit("ok", newFileData.remark)
    } catch (e) {
      console.error(e)
      throw e
    } finally {
      pending.value = false
    }
  })
}
const onCancel = () => {
  emit("cancel")
}
</script>
