<template>
  <v-table>
    <tbody>
      <tr>
        <td>{{ $t("file.details.type") }}</td>
        <td>
          <v-icon class="text-medium-emphasis" size="small" :icon="fileData.type === 'IMAGE' ? 'image' : 'insert_drive_file'" />
          {{ fileData.type }}
        </td>
      </tr>
      <tr>
        <td>{{ $t("file.details.description") }}</td>
        <td>{{ fileData.description }}</td>
      </tr>
      <tr>
        <td>{{ $t("file.details.size") }}</td>
        <td>{{ prettyBytes(fileData.size) }}</td>
      </tr>
      <tr>
        <td>{{ $t("file.details.extension") }}</td>
        <td>{{ fileData.extension }}</td>
      </tr>
      <tr>
        <td>{{ $t("file.details.visibility") }}</td>
        <td>
          <span>
            <v-icon class="text-medium-emphasis mr-1" :icon="fileData.private ? 'lock' : 'lock_open'" />
            {{ fileData.private ? $t("file.visibility.private") : $t("file.visibility.public") }}
          </span>
        </td>
      </tr>
      <tr>
        <td>{{ $t("file.details.lastModifiedAt") }}</td>
        <td>{{ $d(new Date(fileData.updatedAt), "long") }}</td>
      </tr>
      <tr>
        <td>{{ $t("file.details.uploadedAt") }}</td>
        <td>{{ $d(new Date(fileData.createdAt), "long") }}</td>
      </tr>
      <tr>
        <td>{{ $t("file.details.uploadedBy") }}</td>
        <td>{{ fileData.uploadedBy?.user?.description ?? "-" }}</td>
      </tr>
      <tr>
        <td>{{ $t("file.details.uploadedByOrganization") }}</td>
        <td>{{ fileData.uploadedBy?.organization?.description ?? "-" }}</td>
      </tr>
      <tr>
        <td>{{ $t("file.details.remark") }}</td>
        <td>{{ fileData.remark || "-" }}</td>
      </tr>
    </tbody>
  </v-table>
</template>

<script setup lang="ts">
import prettyBytes from "pretty-bytes"

const props = defineProps<{ fileData: FileData }>()
const { fileData } = toRefs(props)
</script>
